import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { database, realTimeRef } from "../contains/firebaseConfig.tsx";
import { onValue } from "firebase/database";

export default function StudyDetail() {
  const location = useLocation();
  const { item } = location.state || {};
const [dataStudy, setDataStudy] = useState<any>([])
  const { id } = useParams();
  const [detail, setDetail] = useState<any>({});

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (dataStudy?.length > 0) {
      const current = dataStudy?.find((study) => study?.id === id);
      if (current) {
        setDetail(current);
      }
    }
  }, [dataStudy]);

  const fetchUserData = () => {
    const storeRef = realTimeRef(database, "study/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setDataStudy(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  return (
    <div className="px-3 py-3 md:py-10 md:px-20">
      <div className="flex">
        <div className="w-full md:w-1/2 xl:w-1/3">
          <img
            src={detail?.url}
            alt=""
            className="w-full h-auto border-[1px]"
          />
        </div>
      </div>
      <p className="text-[#0054a6] md:text-[40px] mt-5 text-[20px]">{detail?.name}</p>

      <div className="w-full">
        <div
          className="mt-10"
          dangerouslySetInnerHTML={{ __html: detail?.description }}
        />
      </div>
    </div>
  );
}
