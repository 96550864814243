import { onValue } from "firebase/database";
import React, { useEffect, useState } from "react";
import { database, realTimeRef } from "../../contains/firebaseConfig.tsx";
import { useNavigate } from "react-router-dom";

function News({ isFull }: { isFull: boolean }) {
  const [dataNews, setdataNews] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    const storeRef = realTimeRef(database, "news/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setdataNews(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  const renderNews = (item: any, index: number) => {
    return (
      <div
        key={index}
        className="flex pl-0 mb-4 overflow-hidden cursor-pointer"
        onClick={() => {
          navigate(`/chi-tiet-tin-tuc/${item?.id}`, { state: { item: item } });
        }}
      >
        <img
          className="rounded-md w-[200px] bg-red-500 object-cover h-[100px]"
          src={item.url}
        />
        <div className="ml-2">
          <strong className="line-clamp-1 md:line-clamp-1">{item.title}</strong>
          <p className="overflow-hidden overflow-ellipsis line-clamp-2">
            <div
              className="line-clamp-2"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </p>
        </div>
      </div>
    );
  };

  const data = isFull ? dataNews : dataNews.slice(1, 6);
  if (dataNews?.length > 0) {
    return (
      <div className="px-0 lg:px-10 xl:px-20 mt-[60px] xl:mt-[80px]">
        <div className="text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-[40px] text-center font-bold text-secondary mb-10">
          TIN TỨC
        </div>
        <div className="flex w-full">
          {dataNews?.length > 0 && (
            <div
              className="hidden w-1/2 overflow-hidden cursor-pointer md:block"
              onClick={() =>
                // navigate("/chi-tiet-tin-tuc", { state: { item: dataNews[0] } })
                navigate(`/chi-tiet-tin-tuc/${dataNews[0]?.id}`, {
                  state: { item: dataNews[0] },
                })
              }
            >
              <img src={dataNews[0].url} className="w-full mb-2 rounded-md" />
              <strong className="text-xl">{dataNews[0].title}</strong>
              <br />
              <div
                className="line-clamp-5"
                dangerouslySetInnerHTML={{ __html: dataNews[0].description }}
              />
            </div>
          )}
          <div className="w-full md:w-1/2 h-auto overflow-auto  max-h-[600px] ml-10">
            {dataNews?.length > 0 && dataNews?.slice(0, 6).map(renderNews)}
          </div>
        </div>
      </div>
    );
  } else {
    if (isFull)
      return (
        <div className="flex  w-full min-h-[600px] justify-center items-center">
          <p className="text-xl text-gray-400 ">Danh sách trống</p>
        </div>
      );
  }
}

export default News;
