import { onValue } from "firebase/database";
import React, { useEffect, useState } from "react";
import { database, realTimeRef } from "../../contains/firebaseConfig.tsx";
import { useNavigate } from "react-router-dom";

function Study({ isFull }: { isFull: boolean }) {
  const [dataStudy, setdataStudy] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchUserData();
    fetchCategory();
  }, []);
  const fetchUserData = () => {
    const storeRef = realTimeRef(database, "study/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setdataStudy(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  const fetchCategory = () => {
    const storeRef = realTimeRef(database, "studyCategory/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setCategory(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };
  const renderStudy = (item: any, index: number) => {
    return (
      <div
        key={index}
        className="flex h-[100px] md:h-[150px] mt-[20px] overflow-hidden w-full justify-center items-center cursor-pointer"
        onClick={() => navigate(`/chi-tiet-nghien-cuu/${item.id}`, { state: { item } })}
      >
        <img className="w-[100px] md:w-[150px]" src={item.url} />
        <div className="w-full ml-3">
          <strong className="line-clamp-2">{item.name}</strong>
          <div
            className="line-clamp-4"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>
      </div>
    );
  };

  const renderCategory = (item: any, index: number) => {
    return (
      <div
        onClick={() => navigate(`/chi-tiet-nghien-cuu/${item.id}`, { state: { item } })}
        className="px-2 py-3 border-b-[1px] border-primary cursor-pointer"
        key={index}
      >
        <p className="hover:text-primary">{item.name}</p>
      </div>
    );
  };

  return (
    <div className="px-10 md:px-20 mt-[60px] xl:mt-[80px] mx-0 md:mx-20">
      <p className="ext-md md:text-lg lg:text-xl xl:text-[30px] 2xl:text-[40px] text-center font-bold text-secondary mb-6">
        NGHIÊN CỨU
      </p>
      {/* <img
        className=""
        src="https://biogaia.vn/images/2023/06/26/anh-nghien-cuu.jpg"
      /> */}
      <div className="flex w-full">
        <div className="w-full lg:w-2/3">
          {isFull
            ? dataStudy?.map(renderStudy)
            : dataStudy?.slice(0, 4).map(renderStudy)}
        </div>
        <div className="hidden w-1/3 h-auto px-5 pt-5 overflow-auto lg:block">
          <div className="py-2 text-center bg-primary">
            <p className="font-semibold text-white">Lĩnh vực nghiên cứu</p>
          </div>
          <div className="w-full h-auto overflow-auto bg-white rounded-md shadow-lg  max-h-[600px]">
            {dataStudy?.length > 0 && dataStudy.map(renderCategory)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Study;
