import { React, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage/index.tsx";
import { RXStore } from "./store/slice/configStore.tsx";
import MainLayout from "./component/MainLayout/index.tsx";
import AddressPage from "./Pages/AddressScreen/index.tsx";
import ProductsPage from "./Pages/Products/index.tsx";
import StudyPage from "./Pages/stydyPage/index.tsx";
import NewsPage from "./Pages/NewsPage/index.tsx";
import ContactPage from "./Pages/Contact/index.tsx";
import LocalImage from "./Pages/LocalImage.tsx";
import ProductDetail from "./Pages/ProductDetail.tsx";
import NewsDetail from "./Pages/NewsDetail.tsx";
import StudyDetail from "./Pages/StudyDetail.tsx";

function App() {
  const loading = false;
  return (
    <>
      <RXStore />
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <>
              <Route
                exact
                path="/"
                name="Home Page"
                element={
                  <MainLayout>
                    <HomePage />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/diem-ban"
                name="Address Page"
                element={
                  <MainLayout>
                    <AddressPage />
                  </MainLayout>
                }
              />
              <Route
                exact
                path="/san-pham"
                name="Product Page"
                element={
                  <MainLayout>
                    <ProductsPage />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/nghien-cuu"
                name="Product Page"
                element={
                  <MainLayout>
                    <StudyPage />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/tin-tuc"
                name="News Page"
                element={
                  <MainLayout>
                    <NewsPage />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/lien-he"
                name="Contact Page"
                element={
                  <MainLayout>
                    <ContactPage />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/hinh-anh"
                name="Image Page"
                element={
                  <MainLayout>
                    <LocalImage />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/chi-tiet-san-pham/:id"
                name="Product detail"
                element={
                  <MainLayout>
                    <ProductDetail />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/chi-tiet-tin-tuc/:id"
                name="News detail"
                element={
                  <MainLayout>
                    <NewsDetail />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/chi-tiet-nghien-cuu/:id"
                name="Study detail"
                element={
                  <MainLayout>
                    <StudyDetail />
                  </MainLayout>
                }
              />
            </>
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  );
}

export default App;
