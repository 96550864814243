import React, { useEffect, useState } from "react";
import Foooter from "../HomePage/Foooter.tsx";
import { Dropdown, MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { onValue } from "firebase/database";
import { database, realTimeRef } from "../../contains/firebaseConfig.tsx";
import { useLocation, useNavigate } from "react-router-dom";

interface IProduct {
  url: string;
  name: string;
  id: number;
}

function ProductsPage() {
  const [dataStore, setdataStore] = useState<any>([]);
  const [dataRootStore, setdataRootStore] = useState<any>([]);
  const [productCategory, setProductCategory] = useState([]);
  const [category, setcategory] = useState<any>(null);
  const navigate = useNavigate();

  const location = useLocation();
  const { search } = location.state || "";

  useEffect(() => {
    fetchUserData();
    fetchCategory();
  }, []);

  useEffect(() => {
    const data = dataRootStore?.filter(
      (item) => item.category?.key === category?.id
    );
    setdataStore(data);
  }, [category]);

  useEffect(() => {
    if (search && typeof search === "string") {
      const data = dataRootStore?.filter((i: any) =>
        i?.name?.toUpperCase()?.includes(search?.toUpperCase())
      );
      setdataStore(data);
    } else {
      setdataStore(dataRootStore);
    }
  }, [search]);

  const fetchUserData = () => {
    const storeRef = realTimeRef(database, "product/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setdataStore(tempData);
        setdataRootStore(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  const fetchCategory = () => {
    const storeRef = realTimeRef(database, "productCategory/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setProductCategory(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  const renderProducts = (item: IProduct, index: number) => {
    return (
      <div
        key={index}
        className="p-3 mt-0 bg-white border-[1px] border-gray-400 rounded-lg hover:scale-105 duration-300 cursor-pointer h-fit"
        onClick={() =>
          navigate(`/chi-tiet-san-pham/${item.id}`, { state: { item: item } })
        }
      >
        <img className="" src={item.url} />
        <p className="mx-2 mt-1 text-xl font-semibold text-center text-primary line-clamp-1">
          {item.name}
        </p>
      </div>
    );
  };

  const items: MenuProps["items"] = productCategory.map((item: any) => {
    return {
      key: item.id,
      label: <button onClick={() => setcategory(item)}>{item?.title}</button>,
    };
  });
  console.log("===category===",category)

  return (
    <div className="h-full">
      <div className="mt-[20px] px-10 md:px-20 flex-1 min-h-1">
        <div className="flex items-start justify-start">
          <div className="flex flex-row px-3 py-2 rounded-md shadow-inner shadow-gray-400">
            <p className="pr-2 mr-2 border-r-2 border-gray-400">Danh mục</p>
            <div className="flex-1 ">
              <Dropdown
                className="w-full pr-2"
                menu={{ items }}
                trigger={["click"]}
              >
                <div className="flex">
                  <p className="w-full mr-2">{category?.title || "Tất cả"}</p>
                  <DownOutlined />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="grid flex-wrap grid-cols-1 gap-5 my-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {dataStore?.length > 0 && dataStore?.map(renderProducts)}
        </div>
        {dataStore?.length === 0 && (
          <div className="flex items-center justify-center w-full min-h-[200px] text-[20px] text-gray-300">
            Danh sách trống
          </div>
        )}
      </div>
      <Foooter />
    </div>
  );
}

export default ProductsPage;
