import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {images} from '../../assets/index.tsx'

const dataRouteAdmin = [
  {
    lable: "Trang chủ",
    id: 1,
    path: "/",
  },
  {
    lable: "Sản phẩm",
    id: 3,
    path: "/san-pham",
  },
  {
    lable: "Nghiên cứu",
    id: 4,
    path: "/nghien-cuu",
  },

  {
    lable: "Tin tức",
    id: 6,
    path: "/tin-tuc",
  },
  {
    lable: "Hình ảnh",
    id: 7,
    path: "/hinh-anh",
  },
  {
    lable: "Liên hệ",
    id: 8,
    path: "/lien-he",
  },
  {
    lable: "Điểm bán",
    id: 9,
    path: "/diem-ban",
  },
];

export default function NavBar() {
  const navigate = useNavigate();

  const [Productname, setProductname] = useState("");

  const dataNav = dataRouteAdmin; // nhưng thứ sẽ hiện ra ở thanh navbar ( đối với loại màn hình to)
  return (
    <div className="">
      <div className="h-6 bg-primary" />
      <div className="px-20 py-4 pb-3 bg-white">
        <div className="flex items-center">
          {/* logo */}
          <div className="cursor-pointer" onClick={()=>navigate("/")}>
            <img
              src={images.logoAz}
              className="w-[150px] h-[80px]"
            />
          </div>

          {/* search */}
          <div className="relative flex justify-center w-full">
            <input
              placeholder="Nhập tên sản phẩm muốn tìm kiếm"
              className="px-5 py-2 bg-white w-[60%] rounded-full h-10 border border-gray-300 outline-none"
              onChange={(e) => setProductname(e.target.value)}
              value={Productname}
            />
            <button
              onClick={() =>
                navigate("/san-pham", { state: { search: Productname } })
              }
              className="flex items-center justify-center w-10 h-10 rounded-full bg-primary translate-x-[-100%]"
            >
              <FaSearch />
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-3 bg-primary">
        {dataNav.map((item, index) => {
          const navKey = window.localStorage.getItem("navKey");
          return (
            <div className="px-5 py-0" key={index + "navRoute"}>
              <div className="flex">
                <div
                  onClick={() => {
                    navigate(item.path);
                    window.localStorage.setItem("navKey", item.id.toString());
                  }}
                  className={"cursor-pointer"}
                  key={index}
                >
                  <div className="text-white">{item.lable}</div>

                  {navKey && parseInt(navKey) === item.id && (
                    <div className="w-full h-[2px] bg-white" />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
