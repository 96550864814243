const images = {
  loginBanner: require("./images/background.jpg"),
  logo: require("./images/LOGO.jpg"),
  logoAz: require("./images/logoBio.png"),
  ic_user: require("./images/ic_user.png"),
  home_banner1: require("./images/home_banner1.png"),
  home_banner2: require("./images/home_banner2.png"),
  location: require("./images/location.png"),
  logoBio: require("./images/logoBio.png"),

};

export { images };
