import React from "react";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { images } from "../../assets/index.tsx";
function Footer() {
  return (
    <div className="items-start justify-start px-5 py-5 md:px-20 bg-primary">
      <div className="flex flex-col items-center md:flex-row md:justify-center">
        <div className="justify-between w-full md:w-1/3 md:ml-20">
          <div className="flex font-semibold text-black">
            <FaLocationDot className="text-xl translate-y-0 md:translate-y-1 md:text-md" />
            <p className="ml-2">
              Địa chỉ: Số 2, DC11, Phường Sơn Kỳ, Quận Tân Phú, Tp HCM
            </p>
          </div>

          <div className="flex mt-2 font-semibold text-black">
            <FaPhone className="translate-y-1 text-md" />
            <p className="ml-2">Hotline: 0931.161.579</p>
          </div>

          <div className="flex mt-2 font-semibold text-black">
            <IoMdMail className="translate-y-1 text-md" />
            <p className="ml-2">Email: biobabylab@gmail.com</p>
          </div>
        </div>

        <div className="flex flex-col items-center w-full mt-5 mb-5 md:w-1/3 md:mt0">
          <img
            alt="Logo"
            src={images.logoAz}
            className="w-[150px] object-contain"
          />
          <p className="font-semibold text-center text-black">
            TRAO GIÁ TRỊ-TẠO NIỀM TIN
            <br />
            CÔNG TY TNHH THƯƠNG MẠI AZ PHARM
          </p>
        </div>

        <div className="items-center w-full mt-5 md:w-1/3 md:md-0">
          <p className="mt-0 font-semibold text-center text-black">
            Các bài viết của AZ PHARM chỉ có tính chất tham khảo, không thay thế
            cho việc chẩn đoán hoặc điều trị y khoa. Các sản phẩm không phải là
            thuốc và không có tác dụng thay thế thuốc chữa bệnh
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
