// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDHItR5wESzzwsKsQSrYvavuPSua-4qkLI",
  authDomain: "biobaby-230d3.firebaseapp.com",
  databaseURL: "https://biobaby-230d3-default-rtdb.firebaseio.com",
  projectId: "biobaby-230d3",
  storageBucket: "biobaby-230d3.appspot.com",
  messagingSenderId: "562501715741",
  appId: "1:562501715741:web:73fbaaad797719598103f4",
  measurementId: "G-CQBPXT660D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const realTimeRef = ref;
export { database, firestore, app, storage, realTimeRef };