import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "./HomePage/Foooter.tsx";
import { database, realTimeRef } from "../contains/firebaseConfig.tsx";
import { onValue } from "firebase/database";

export default function ProductDetail() {
  const location = useLocation();
  const { item } = location.state || {};
  const navigate = useNavigate()
  const [dataProduct, setDataProduct] = useState<any>([]);
  const [detail, setDetail] = useState<any>({})
  const { id } = useParams();
  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if(dataProduct?.length>0){
      const current = dataProduct?.find((product) => product?.id === id);
      if (current) {
        setDetail(current);
      }
    }
  }, [dataProduct])
  
  const fetchUserData = () => {
    const storeRef = realTimeRef(database, "product/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setDataProduct(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };
  return (
    <div>
      <div className="mx-2 my-2 md:mx-20 md:my-10">
        <div className="flex flex-col w-full md:flex-row">
          <div className="w-full mr-10 md:w-1/2 xl:w-1/3">
            <img src={detail?.url} className="object-contain" />
          </div>

          <div className="w-full py-10 md:w-1/2">
            <p className="text-[#0054a6] md:text-[40px] text-[20px]">{detail?.name}</p>

            <div className="mt-10">
              <div className="cursor-pointer" onClick={()=>navigate("/lien-he")}>
                <p className="mt-10 font-bold text-[40px] text-primary">
                  GIÁ: LIÊN HỆ
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-10"
          dangerouslySetInnerHTML={{ __html: detail?.description }}
        />
      </div>
      <Footer />
    </div>
  );
}
