import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSelect } from "../../store/slice/auth.slice.tsx";
import MobileNavBar from "../MobileNav/NavBar.tsx";
import NavBar from "../nav/NavBar.tsx";

interface MainLayoutProps {
  children: any;
}
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { isLogin } = useSelector(authSelect);
  const navigate = useNavigate();

  return (
    <div className="h-screen">
      <MobileNavBar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className={`md:block hidden overflow-y-auto bg-primary`}>
        <NavBar />
      </div>
      <div className="w-full overflow-y-auto bg-white scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <div
          onClick={() => setIsOpen(true)}
          className="w-screen mx-5 my-5 text-xl text-white translate-x-[-20px] translate-y-[-20px] cursor-pointer md:hidden bg-primary py-2 px-2 flex items-center fixed h-[60px] z-50"
        >
          <AiOutlineMenu />

          <div className="relative flex justify-center w-full">
            <input
              placeholder="Tìm kiếm"
              className="px-5 py-2 bg-white w-[60%] rounded-md h-10 border border-gray-300 outline-none"
            />
            <button className="flex items-center justify-center w-10 h-10 bg-white border border-gray-300 rounded-md text-primary">
              <FaSearch />
            </button>
          </div>
        </div>
        <div className="pt-[60px] md:pt-0">{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
