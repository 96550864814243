import React, { useState } from "react";
import { Input, Typography, Button } from "antd";
import { push, set } from "firebase/database";
import { database, realTimeRef } from "../../contains/firebaseConfig.tsx";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { TextArea } = Input;
function Contact() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  
  const onSubmit = () => {
    const body = {
      name: name,
      email: email,
      phone: phone,
      content: content,
    };
    const newDocref = push(realTimeRef(database, "user"));
    set(newDocref, {
      ...body,
    }).then(() => {
      // navigate("/");
      notify("Đã gửi thông tin đến bộ phận CSKH");
    });
  };

  const notify = (title: string) => toast(title);

  
  return (
    <div
      style={{
        backgroundImage: `url(${"https://t4.ftcdn.net/jpg/02/10/45/95/360_F_210459536_XmLDEcKq2DpeNLVmheuWeu9NM9aGKnih.jpg"})`,
      }}
      className="flex flex-col items-center justify-center object-contain w-screen py-10 bg-center bg-no-repeat bg-cover"
    >
      <p className="text-[40px] font-semibold text-white text-center mb-5">
        LIÊN HỆ
      </p>

      <div className="w-3/4 md:w-1/3 xl:w-1/3">
        <div>
          <Typography>{"Họ tên"}</Typography>
          <Input
            value={name}
            onChange={(e) => setname(e.target.value)}
            placeholder="Nhập họ và tên"
          />
        </div>

        <div className="mt-5">
          <Typography>{"Email"}</Typography>
          <Input
            value={email}
            onChange={(e) => setemail(e.target.value)}
            placeholder="Nhập email"
          />
        </div>

        <div className="mt-5">
          <Typography>{"Số điện thoại"}</Typography>
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Nhập số điện thoại"
          />
        </div>

        <div className="mt-5">
          <Typography>{"Nội dung"}</Typography>
          <TextArea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={6}
          />
        </div>

        <Button
          disabled={!name || !email || !phone || !content ? true : false}
          className="px-10 mt-5"
          onClick={onSubmit}
        >
          Gửi
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Contact;
