import { Carousel } from "antd";
import React, { useEffect, useState } from "react";
import { database, realTimeRef } from "../../contains/firebaseConfig.tsx";
import { onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";

interface IProduct {
  url: string;
  name: string;
  id: number;
}
function ProducCarousel() {
  const [dataStore, setdataStore] = useState<any>([]);
  useEffect(() => {
    fetchUserData();
  }, []);
  const navigate = useNavigate();
  const fetchUserData = () => {
    const storeRef = realTimeRef(database, "product/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setdataStore(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  const renderProducts = (item: IProduct, index: number) => {
    return (
      <div
        className="flex-col items-center justify-center p-10 mr-5 bg-transparent cursor-pointer"
        key={index}
        onClick={() =>
          navigate(`/chi-tiet-san-pham/${item.id}`, { state: { item: item } })
        }
      >
        <div className="p-3 mb-1 bg-white rounded-lg">
          <img className="" src={item.url} />
          <p className="mx-2 mt-1 text-xl font-semibold text-center text-secondary line-clamp-1">
            {item.name}
          </p>
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        backgroundImage: `url(${"https://bioamicus.vn/themes/storefront/public/images/nen-3.png"})`,
      }}
      className="py-10 mt-[80px] w-full"
    >
      <p className="text:md md:text-lg lg:text-xl xl:text-2xl 2xl:text-[40px] text-primary font-bold text-center">
        SẢN PHẨM
      </p>

      {/* //sm */}
      <div className="block md:hidden">
        <Carousel autoplay slidesToShow={1}>
          {dataStore?.length > 0 && dataStore?.map(renderProducts)}
        </Carousel>
      </div>

      {/* //md */}
      <div className="hidden md:block lg:hidden">
        <Carousel autoplay slidesToShow={3}>
          {dataStore?.length > 0 && dataStore?.map(renderProducts)}
        </Carousel>
      </div>

      {/* //lg */}
      <div className="hidden lg:block">
        <Carousel autoplay slidesToShow={4}>
          {dataStore?.length > 0 && dataStore?.map(renderProducts)}
        </Carousel>
      </div>
    </div>
  );
}

export default ProducCarousel;
