import { Carousel } from "antd";
import { onValue } from "firebase/database";
import React, { useEffect, useState } from "react";
import { database, realTimeRef } from "../../contains/firebaseConfig.tsx";

function LocalImage() {
  const [dataNews, setDataNews] = useState<any>([]);
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    const productRef = realTimeRef(database, "localImage/");
    onValue(
      productRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setDataNews(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  const renderProducts = (item: any, index: number) => {
    return (
      <div
        className="flex-col items-center justify-center p-5 mr-5 bg-transparent"
        key={index}
      >
        <div className="p-3 mb-1 bg-white rounded-lg border-[1px]">
          <img className="" src={item.url} />
        </div>
      </div>
    );
  };

  return (
    <div className="px-10 mt-[60px] xl:mt-[80px] mx-0 md:mx-20 md:px-20">
      <p className="ext-md md:text-lg lg:text-xl xl:text-[30px] 2xl:text-[40px] text-center font-semibold text-secondary mb-6">
        HÌNH ẢNH
      </p>

      {/* //sm */}
      <div className="block md:hidden">
        <Carousel autoplay slidesToShow={1}>
          {dataNews.map(renderProducts)}
        </Carousel>
      </div>

      {/* //md */}
      <div className="hidden md:block lg:hidden">
        <Carousel autoplay slidesToShow={3}>
          {dataNews.map(renderProducts)}
        </Carousel>
      </div>

      {/* //lg */}
      <div className="hidden lg:block">
        <Carousel autoplay slidesToShow={4}>
          {dataNews.map(renderProducts)}
        </Carousel>
      </div>
    </div>
  );
}

export default LocalImage;
