import React, { useEffect } from "react";
import { Carousel } from "antd";
import ProducCarousel from "./producCarousel.tsx";
import AddressList from "./AddressList.tsx";
import News from "./News.tsx";
import Study from "./Study.tsx";
import Contact from "./Contact.tsx";
import Foooter from "./Foooter.tsx";
import LocalImage from "./Imagelocal.tsx";
import { images } from "../../assets/index.tsx";
import { useLocation } from "react-router-dom";

const dataBaner = [
  {
    id: 1,
    url: images.home_banner1,
  },
  {
    id: 2,
    url: images.home_banner2,
  },
];

interface IBanerItem {
  id: number;
  url: string;
}
function HomePage() {
  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
    window.scrollTo(0,0)
    }, 10);
  }, [pathname]);

  const renterItem = (item: IBanerItem, index: number) => {
    return (
      <div className="flex w-full">
        <img
          key={index}
          src={item.url}
          className="object-contain w-full"
          alt="banner"
        />
      </div>
    );
  };

  return (
    <div className="">
      <div className="flex justify-center mx-0 md:mx-20">
        <div className="w-full px-5 mt-5 md:mt-10 lg:px-10 xl:px-20">
          <Carousel autoplay dots afterChange={onChange}>
            {dataBaner.map(renterItem)}
          </Carousel>
        </div>
      </div>
      <ProducCarousel />
      <AddressList />
      <News isFull={false} />
      <LocalImage />
      <Study isFull={false} />
      <div className="mt-[60px] xl:mt-[80px]">
        <Contact />
      </div>
      <Foooter />
    </div>
  );
}

export default HomePage;
