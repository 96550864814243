import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/index.tsx";
import { authSelect } from "../../store/slice/auth.slice.tsx";

const dataRouteAdmin = [
  {
    lable: "Trang chủ",
    id: 1,
    path: "/",
  },
  {
    lable: "Sản phẩm",
    id: 3,
    path: "/san-pham",
  },
  {
    lable: "Nghiên cứu",
    id: 4,
    path: "/nghien-cuu",
  },

  {
    lable: "Tin tức",
    id: 6,
    path: "/tin-tuc",
  },
  {
    lable: "Hình ảnh",
    id: 7,
    path: "/hinh-anh",
  },
  // {
  //   lable: "Hỏi đáp",
  //   id: 7,
  //   path: "/",
  // },
  {
    lable: "Liên hệ",
    id: 8,
    path: "/lien-he",
  },
  {
    lable: "Điểm bán",
    id: 9,
    path: "/diem-ban",
  },
];

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}
export default function MobileNavBar({ isOpen, setIsOpen }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(authSelect);
  const dataNav = dataRouteAdmin; // nhưng thứ sẽ hiện ra ở thanh navBar
  const navAnimation = isOpen ? "translate-x-0" : "translate-x-[-100%]";
  return (
    <div
      className={`fixed ${navAnimation} h-full min-h-screen duration-300 top-0 left-0 right-0 bottom-0 z-[10000] bg-primary overflow-y-auto`}
    >
      <div className="py-4">
        <div className="flex items-center justify-center">
          <img
            src={images.logoAz}
            alt="Logo"
            className="w-[90px] h-[90px] object-contain"
          />
          <div className="ml-2 text-3xl font-bold text-white">BIOBABILAB</div>
        </div>
        <AiFillCloseCircle
          onClick={() => setIsOpen(false)}
          className="w-[30px] h-[30px] absolute right-2 top-2 cursor-pointer text-white"
        />
        <div className="mt-5">
          {dataNav.map((item, index) => {
            const navKey = window.localStorage.getItem("navKey");
            return (
              <div
                className="flex flex-col items-center justify-center py-5 text-center"
                key={index + "navRoute"}
              >
                <div
                  onClick={() => {
                    setIsOpen(false);
                    navigate(item.path);
                    window.localStorage.setItem("navKey", item.id.toString());
                  }}
                  className={`
                        rounded-xl
                        px-2 
                        mt-1 
                        cursor-pointer
                        flex
                        items-center
                        min-h-[40px]
                        text-center
                        flex-col
                        `}
                  key={index}
                >
                  <div className="text-xl font-semibold text-center text-white">
                    {item.lable}
                  </div>
                  {navKey && parseInt(navKey) === item.id && (
                    <div className="bg-white w-full h-[2px]"></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
