import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { database, realTimeRef } from "../contains/firebaseConfig.tsx";
import { onValue } from "firebase/database";

export default function NewsDetail() {
  const [dataNews, setdataNews] = useState<any>([]);
  const { id } = useParams();
  const location = useLocation();
  const [detail, setDetail] = useState<any>({});
  const { item } = location.state || {};

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (dataNews?.length > 0) {
      const current = dataNews?.find((news) => news?.id === id);
      if (current) {
        setDetail(current);
      }
    }
  }, [dataNews]);

  const fetchUserData = () => {
    const storeRef = realTimeRef(database, "news/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setdataNews(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  return (
    <div className="mx-10 my-10">
      <div className="w-full mr-10">
        <img src={detail?.url} className="object-contain w-full bg-red-400" />
      </div>
      <div className="flex w-full">
        <div className="w-full py-10">
          <p className="text-[#0054a6] text-[40px]">{detail?.title}</p>
          <div
            className="mt-10"
            dangerouslySetInnerHTML={{ __html: detail.description }}
          />
        </div>
      </div>
    </div>
  );
}