import React from "react";
import Foooter from "../HomePage/Foooter.tsx";
import News from "../HomePage/News.tsx";

function NewsPage() {
  return (
    <div>
      <News isFull={true} />
      <div className="mt-10">
        <Foooter />
      </div>
    </div>
  );
}

export default NewsPage;
