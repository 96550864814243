import React from "react";
import AddressList from "../HomePage/AddressList.tsx";
import Foooter from "../HomePage/Foooter.tsx";

function AddressPage() {
  return (
    <div>
      <div className="mb-10">
        <AddressList />
      </div>
      <Foooter />
    </div>
  );
}

export default AddressPage;
