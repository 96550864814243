import { Carousel } from "antd";
import { onValue } from "firebase/database";
import React, { useEffect, useState } from "react";
import { database, realTimeRef } from "../contains/firebaseConfig.tsx";
import ImageLocal from "./HomePage/Imagelocal.tsx";
import Footer from "./HomePage/Foooter.tsx";
function LocalImage() {
  return (
    <div>
      <div className="px-10 mt-[60px] xl:mt-[80px]">
        <ImageLocal />
      </div>
      <Footer />
    </div>
  );
}

export default LocalImage;
