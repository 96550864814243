import React from "react";
import Study from "../HomePage/Study.tsx";
import Foooter from "../HomePage/Foooter.tsx";

function StudyPage() {
  return (
    <div>
      <Study isFull={true} />
      <div className="mt-10">
        <Foooter />
      </div>
    </div>
  );
}

export default StudyPage;
