import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { onValue } from "firebase/database";
import React, { useEffect, useState } from "react";
import { database, realTimeRef } from "../../contains/firebaseConfig.tsx";
import useGHN from "../../hook/getDataGHN.ts";
import { images } from "../../assets/index.tsx";

function AddressList() {
  const [GHNprovinceId, setGHNProvinceId] = useState<number | null>(null);
  const [GHNdistrictId, setGHNDistrictId] = useState(null);
  const [provinceName, setprovinceName] = useState("");
  const [districName, setdistricName] = useState("");
  const [rootStoreData, setrootStoreData] = useState([]);

  const { data: ProvinceDataGHN } = useGHN({
    url: "shiip/public-api/master-data/province",
  });
  const { data: DistrictDataGHN } = useGHN({
    url: `shiip/public-api/master-data/district?province_id=${GHNprovinceId}`,
    dep: GHNprovinceId,
  });

  const [dataStore, setdataStore] = useState<any>([]);
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    const storeRef = realTimeRef(database, "store/");
    onValue(
      storeRef,
      (snapshot) => {
        const data = snapshot.val();
        let tempData: any = [];
        for (const key in data) {
          tempData = [...tempData, { ...data[key], id: key }];
        }
        setdataStore(tempData);
        setrootStoreData(tempData);
      },
      {
        onlyOnce: true,
      }
    );
  };

  const renderAddress = (item: any, index: number) => {
    return (
      <div
        className="border-b-[1px] border-gray-200 px-10 py-2 cursor-pointer"
        key={index}
      >
        <strong>{item.title}</strong>
        <p>{item.address}</p>
      </div>
    );
  };

  const items: MenuProps["items"] = ProvinceDataGHN.map((item: any, index) => {
    return {
      key: index.toString(),
      label: (
        <button
          onClick={() => {
            setGHNProvinceId(item?.ProvinceID);
            setprovinceName(item?.ProvinceName);
            setdistricName("");
          }}
        >
          {item?.ProvinceName}
        </button>
      ),
    };
  });

  const itemsDistric: MenuProps["items"] = DistrictDataGHN.map(
    (item: any, index: number) => {
      return {
        key: index.toString(),
        label: (
          <button
            onClick={() => {
              setGHNDistrictId(item?.DistrictID);
              setdistricName(item?.DistrictName);
            }}
          >
            {item?.DistrictName}
          </button>
        ),
      };
    }
  );

  const onSearchAddress = () => {
    const data = rootStoreData.filter(
      (i: any) =>
        i?.district?.id === GHNdistrictId && i?.province?.id === GHNprovinceId
    );
    setdataStore(data);
  };

  return (
    <div className="px-5 md:px-10 xl:px-20 mt-[30px] lg:mt-[80px] mx-0 md:mx-20">
      <p className="mx-0 leading-relaxed text-md font-bold text-center text-secondary md:text-lg lg:text-xl xl:text-[30px]">
        ĐIỂM BÁN
      </p>

      <div className="flex items-center mt-[20px] lg:mt-[60px]">
        <div className="w-full lg:w-1/2">
          <div className="flex flex-col w-full px-3 py-2 lg:flex-row bg-gradient-to-r from-blue-500 to-purple-600">
            <div className="flex flex-col flex-1 mr-0 lg:mr-2 lg:flex-row ">
              <div className="flex w-full py-2 mt-2 bg-white rounded-md lg:w-1/2">
                <p className="border-r-[1px] pr-1 mr-1 px-1 border-r-gray-500">
                  Tỉnh/TP
                </p>
                <Dropdown
                  className="w-full pr-2"
                  menu={{ items }}
                  overlayStyle={{
                    height: "300px",
                    overflowY: "hidden",
                    overflow: "auto",
                  }}
                  trigger={["click"]}
                >
                  <div className="flex">
                    <p className="w-full line-clamp-1">
                      {provinceName || "Tất cả"}
                    </p>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </div>

              <div className="flex w-full py-2 mt-2 bg-white rounded-md lg-mt-0 lg:ml-2 lg:w-1/2">
                <p className="border-r-[1px] pr-1 mr-1 px-1 border-r-gray-500">
                  Q/Huyện
                </p>
                <Dropdown
                  className="w-full pr-2"
                  menu={{ items: itemsDistric }}
                  trigger={["click"]}
                  overlayStyle={{
                    height: "300px",
                    overflowY: "hidden",
                    overflow: "auto",
                  }}
                >
                  <div className="flex">
                    <p className="w-full line-clamp-1">
                      {districName || "Tất cả"}
                    </p>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </div>
            </div>

            <div
              className="
              flex
              items-center
              justify-center
              px-5
              mt-2
              font-semibold
            text-white
            bg-orange-500
              rounded-md
              py-[5px]
              cursor-pointer
              "
              onClick={onSearchAddress}
            >
              Tìm
            </div>
          </div>

          <div className="w-full h-[300px] overflow-auto bg-white shadow-2xl rounded-b-xl">
            {dataStore?.length > 0 && dataStore?.map(renderAddress)}
          </div>
        </div>
        <img
          src={images.location}
          className="h-[400px] object-contain ml-20 w-1/2 hidden lg:block"
        />
      </div>
    </div>
  );
}

export default AddressList;
