import { useEffect } from "react";
import { useState } from "react";

interface Props {
  url?: string;
  query?: any;
  dep?: any;
  domain?: string;
  body?: any;
  method?: string;
}
const useGHN = ({
  url = "",
  query,
  dep,
  domain = "https://online-gateway.ghn.vn/",
  body,
  method = "GET",
}: Props) => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    getData();
  }, [dep]);

  const getData = async () => {
    let _query = "";
    for (const key in query) {
      _query += `&${key}=${query[key]}`;
    }
    setloading(true);
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Token: `8977458b-e531-11ee-aebc-56bc015a6b03`,
      },
    };

    const response = await fetch(
      `${domain}${url}` + _query?.replace("&", "?"),
      option
    );
    const resJson = await response.json();
    setloading(false);
    if (resJson?.code === 200) {
      setData(resJson?.data);
    }
  };

  return {
    data,
    setData,
    loading,
  };
};

export default useGHN;
