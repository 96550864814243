 import React from 'react'
import Foooter from '../HomePage/Foooter.tsx'
import Contact from '../HomePage/Contact.tsx'
 
 function ContactPage() {
   return (
     <div>
       <Contact/>
       <div>
        <Foooter/>
       </div>
     </div>
   )
 }
 
 export default ContactPage
 